import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'money',
})
export class MoneyPipe implements PipeTransform {

  constructor() {
    
  }

  ionViewDidLoad(){

  }

  transform(value) {
    value = Number(value);
    return new CurrencyPipe('USD').transform(value, 'USD', 'symbol-narrow', '1.0-2', 'en');
  }
}
