import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  DB: any = '/';

  constructor(private db: AngularFireDatabase) { }

  getRef(url): firebase.database.Reference {
    return firebase.database().ref(url);
  }

  getNonprofit(key) {
    return new Promise((resolve) => {
      this.getRef(`${this.DB}nonprofit/${key}`).once('value', (res) => {
        const data = res.val() ? res.val() : [];
        resolve(data);
      });
    });
  }

  getAccounts(key) {
    return new Promise((resolve) => {
      this.getRef(`${this.DB}accounts/${key}`).once('value', (res) => {
        const data = res.val() ? res.val() : [];
        resolve(data);
      });
    });
  }

  getPrograms(key) {
    return new Promise((resolve) => {
      this.getRef(`${this.DB}nonprofit_programs/${key}`).once('value', (res) => {
        const data = res.val() ? Object.values(res.val()) : [];
        resolve(data);
      });
    });
  }

  getIncome(key) {
    return new Promise((resolve) => {
      this.getRef(`${this.DB}income/${key}`).once('value', (res) => {
        const data = res.val() ? res.val() : [];
        resolve(data);
      });
    });
  }

  getExpenses(key) {
    return new Promise((resolve) => {
      this.getRef(`${this.DB}expenses/${key}`).once('value', (res) => {
        const data = res.val() ? res.val() : [];
        resolve(data);
      });
    });
  }

  getProject(key) {
    return new Promise((resolve) => {
      this.getRef(`${this.DB}project/${key}`).once('value', (res) => {
        const data = res.val() ? res.val() : [];
        resolve(data);
      });
    });
  }

  getUser(uid){
    return new Promise((resolve) => {
      this.getRef(`${this.DB}profile/${uid}`).once('value', (res) => {
        resolve(res.val());
      });
    });    
  }

}
