import { Component, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormBuilder } from '@angular/forms';

import { MoneyPipe, FormatterPipe } from '../../pipes/pipes';

import { Title } from '@angular/platform-browser';
import { Chart } from 'chart.js';
import * as jsPDF from 'jspdf'

import { DatabaseService } from '../services/database.service';
import { ActivatedRoute, Resolve } from '@angular/router';
import 'chartjs-plugin-labels';

import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-nonprofits',
  templateUrl: './nonprofits.component.html',
  styleUrls: ['./nonprofits.component.scss']
})
export class NonprofitsComponent implements OnInit {
  @ViewChild('myselect') myselect;
  
  idToken: string = this.route.snapshot.paramMap.get('idtoken');
  nonprofit: any = {
    id: this.route.snapshot.paramMap.get('id'),
    img: {
      logo: null
    }
  };

  collapsed = true;
  spinner: any = true;

  user: any = {};

  total: any = {
    revenue:{
      actual: 0,
      budget: 0
    },
    expenses: {
      actual: 0,
      budget: 0
    },
    raised: 0,
    need: 0
  }

  listPrograms: any = [];
  listYear: any = [];
  listMonth: any = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'Ocotober', 'November', 'December'];
  
  public form: FormGroup;
  toggle: boolean = false;
  income: any = []; expense: any = []; project: any = [];

  program: any;
  year: any = new Date().getFullYear();
  month: any = new Date().getMonth();
  tableIncome: any; tableExpense: any; 
  
  tableProject: any = [{}];

  public accounts: any = {
    income: [],
    expenses: [],
    project: []
  }

  constructor(public fb: FormBuilder, public http: HttpClient, private title: Title, private db: DatabaseService, private chRef: ChangeDetectorRef, private route: ActivatedRoute) {
    this.form = this.fb.group({
      month: this.month,
      year: this.year,
      program: this.program
    });

    for(let step = 0; step < 3; step++)
      this.listYear.push(new Date().getFullYear() - step);
  }

  loadPieChart() {
    this.chRef.detectChanges();
    const ctx = document.getElementById('pie');
    const myChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: ['Support & revenue', 'Expenses'],
        datasets: [{
          label: '# of Votes',
          data: [this.total.revenue.actual, this.total.expenses.actual],
          backgroundColor: [
            '#1dd189',
            '#eb7556'
          ]
        }]
      },
      options: {
        labels: {
          precision: 0,
          fontSize: 18,
          fontColor: '#fff',
          fontStyle: 'bold',
        },
        legend: {
          // display: false,
        },
        scales: {
          xAxes: [{
            ticks: {
              display: false
            },
            gridLines: {
              display: false,
              drawBorder: false
            }
          }],
          yAxes: [{
            ticks: {
              display: false
            },
            gridLines: {
              display: false,
              drawBorder: false
            }
          }]
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: function (tooltipItem, data) {
              const indice = tooltipItem.index;
              return data.labels[indice] + ': ' + '$' + data.datasets[0].data[indice].toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + '';
            }
          }
        },
      }
    });
  }

  loadLineChart() {
    this.chRef.detectChanges();

    let income: any = Object.values(this.income[this.form.value.year]);
    let expenses: any = Object.values(this.expense[this.form.value.year]);
    let a = []; let b = []; let labels = [];

    for(let i in income){
      let actual = 0; let budget = 0;
      for(let j in income[i]){
        for(let k in income[i][j].accounts){
          actual += income[i][j].accounts[k].actual;
          budget += income[i][j].accounts[k].budget;
        }
      }
      a.push(actual);
    }

    for(let i in expenses){
      let actual = 0; let budget = 0;
      for(let j in expenses[i]){
        for(let k in expenses[i][j].accounts){
          actual += expenses[i][j].accounts[k].actual;
          budget += expenses[i][j].accounts[k].budget;
        }
      }
      b.push(actual);
    }

    for(let i in income){
      labels.push(this.listMonth[i].substr(0, 3));
    }

    const ctx = document.getElementById('line');
    const myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          label: 'Support & revenue',
          data: a,
          backgroundColor: '#1dd189',
          // fill: true,
          spanGaps: true,
        },{
          label: 'Expenses',
          data: b,
          backgroundColor: '#eb7556',
          // fill: true,
          spanGaps: true,
        }]
      },
      options: {
        plugins:{
          labels:{
            render: function(args) {
              // return MoneyPipe.prototype.transform(args.value);
            },
            fontSize: 10,
            fontColor: '#333'
          }
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              return MoneyPipe.prototype.transform(tooltipItem.yLabel);
            }
          }
        },
        scales: {
          yAxes: [{
              ticks: {
                  beginAtZero: true,
                  callback: function(value, index, values) {
                    return FormatterPipe.prototype.transform(value);
                  },
              },
              gridLines: {
                  display: false,
                  drawBorder: false,
              },
          }],
          xAxes: [{
              ticks: {
                  beginAtZero: true,
                  callback: function(value, index, values) {
                      return value;
                  },
              },
              gridLines: {
                  display: false,
                  drawBorder: false,
              },
          }],
        }
      }
      
    });
  }

  encodeImageUri(uri) {
    return new Promise((resolve, reject) => {
      var c = document.createElement('canvas');
      var ctx = c.getContext("2d");
      var img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function () {
        var aux:any = this;
        c.width = aux.width;
        c.height = aux.height;
        ctx.drawImage(img, 0, 0);
        var dataURL = c.toDataURL("image/jpeg");
        // callback(dataURL);
        // console.log(dataURL);
        resolve(dataURL);
      };
      img.onerror = function(err){
        reject(err);
      }
      img.src = uri;
    });
  };

  download(){
    console.log(this.toggle);

    var doc = new jsPDF();
    var title = this.toggle ? this.listMonth[0] + ' ' + this.form.value.year + ' to ' + this.listMonth[this.form.value.month - 1] : this.listMonth[this.form.value.month - 1];

    doc.setProperties({
      title: this.nonprofit.name + ' ' + title + ' ' + this.form.value.year + ' Income Statement',
      subject: 'Income Statement',		
      author: 'Fundra',
      keywords: 'generated, javascript, web 2.0, ajax',
      creator: 'MEEE'
    });

    var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    
    //this.encodeImageUri(this.nonprofit.img.logo).then((res) => {
      //doc.addImage(res, 'JPEG', pageWidth-40, 15, 30, 20);

      doc.setFontSize(13);
      doc.setFontType("bold");
      doc.text(20, 20, this.nonprofit.name);
      
      doc.setFontSize(10);
      doc.text(20, 25, this.toggle ? 'Yearly Income Statement' : 'Monthly Income Statement');
      
      if(this.toggle) 
        doc.text(20, 30, this.listMonth[0] + ' ' + this.form.value.year + ' to ' + this.listMonth[this.form.value.month - 1] + ' ' + this.form.value.year);
      else 
        doc.text(20, 30, this.listMonth[this.form.value.month - 1] + ' ' + this.form.value.year);

      doc.text(20, 50, 'Revenue');
      doc.setFontType("normal");
      let y = 50;
      for(let i in this.accounts.income){
        y += 5;
        doc.text(20, y, this.accounts.income[i].name);
        doc.text(MoneyPipe.prototype.transform(this.accounts.income[i].actual), pageWidth - 20, y, {align: 'right'});
      }
      doc.setFontType("bold");
      y += 5;
      doc.text(20, y, 'Total');
      doc.text(MoneyPipe.prototype.transform(this.total.revenue.actual), pageWidth - 20, y, {align: 'right'});

      y += 10;
      doc.text(20, y, 'Expenses');
      doc.setFontType("normal");
      for(let i in this.accounts.expenses){
        y += 5;
        doc.text(20, y, this.accounts.expenses[i].name);
        doc.text(MoneyPipe.prototype.transform(this.accounts.expenses[i].actual), pageWidth - 20, y, {align: 'right'});
      }
      doc.setFontType("bold");
      y += 5;
      doc.text(20, y, 'Total');
      doc.text(MoneyPipe.prototype.transform(this.total.expenses.actual), pageWidth - 20, y, {align: 'right'});

      y += 20;
      doc.setFontSize(13);
      let loss = this.total.revenue.actual > this.total.expenses.actual ? false: true;
      doc.text(20, y, loss ? 'Net Loss' : 'Net Profit');
      if(loss) doc.setTextColor(255,0,0);
      else doc.setTextColor(29,209,137);
      doc.text((MoneyPipe.prototype.transform(this.total.revenue.actual - this.total.expenses.actual)).toString(), pageWidth - 20, y, {align: 'right'});
      
      doc.setTextColor(0,0,0);
      doc.setFontSize(10);
      let footer = 'Copyright 2019. Alright Reserved';
      doc.text(footer, pageWidth / 2, pageHeight  - 10, 'center');
      // doc.output('dataurlnewwindow');

      let tmp = this.toggle ? '' : this.listMonth[this.form.value.month - 1] + ' ';
      doc.save(this.nonprofit.name + ' ' + tmp + this.form.value.year + ' Income Statement');
    // });
  }

  setToggle() {
    this.toggle = !this.toggle;
    console.log(this.toggle);
  }

  setMonthly(){
    console.log(this.toggle);
    this.toggle = false;
    this.filter();
  }

  setYearly(){
    // console.log(this.toggle);
    this.toggle = true;
    this.filter();
  }

  filter(){
    // console.log(this.form.value.month);
    this.form.controls['month'].patchValue(this.form.value.month);
    this.loadIncome(this.form.value.year, this.form.value.month, this.form.value.program);
    this.loadExpense(this.form.value.year, this.form.value.month, this.form.value.program);
    this.loadProject(this.form.value.year, this.form.value.month, this.form.value.program);
  }
  
  dataYearly(data, year, program, id){
    let tmp = []; let cart = [];

    if(program && program.id != 0){
      for(let i in data[year]){
        for(let j in data[year][i]){
          if(data[year][i][j].id == program.id){
            for(let k in Object.values(data[year][i][j].accounts)){
              tmp.push(Object.values(data[year][i][j].accounts)[k]);  
            }
          }
        }
      }
    }else{
      for(let i in data[year]){
        for(let j in data[year][i]){
          for(let k in Object.values(data[year][i][j].accounts)){
            tmp.push(Object.values(data[year][i][j].accounts)[k]);
          }
        }
      }
    }

    cart = Object.values([...tmp].reduce((acc, { id, name, actual, budget }) => {
      acc[id] = { id, name, actual: (acc[id] ? acc[id].actual : 0) + actual, budget: (acc[id] ? acc[id].budget : 0) + budget  };
      return acc;
    }, {}));

    if(id == 1) this.accounts.income = cart;
    if(id == 2) this.accounts.expenses = cart;
  }

  dataMonthly(data, month, program, id){
    let accounts = [];
    let cart = []; let tmp = [];
    if(program && program.id != 0){
      cart = data[month][program.id] ? Object.values(data[month][program.id].accounts) : [];
    }else {
      cart = [];
      for(let i in data[month]){
        accounts[i] = Object.values(data[month][i].accounts);
        for(let j in accounts[i])
          tmp.push(accounts[i][j]);
      }
      
      cart = Object.values([...tmp].reduce((acc, { id, name, actual, budget }) => {
        acc[id] = { id, name, actual: (acc[id] ? acc[id].actual : 0) + actual, budget: (acc[id] ? acc[id].budget : 0) + budget  };
        return acc;
      }, {}));
    }

    if(id == 1) this.accounts.income = cart;
    if(id == 2) this.accounts.expenses = cart;
  }

  loadIncome(year, month, program){
    this.accounts.income = [];
    let actual = 0; let budget = 0;

    if(month > 0 && this.income[year] && !this.toggle)
      this.dataMonthly(this.income[year], month, program, 1);
    if(this.toggle)
      this.dataYearly(this.income, year, program, 1);
  
    this.tableIncome.rows().remove().draw();

    if(this.accounts.income.length > 0){
      for(let i in this.accounts.income){
        actual += this.accounts.income[i].actual; budget += this.accounts.income[i].budget;
        this.accounts.income[i].status = this.accounts.income[i].actual > this.accounts.income[i].budget ? '<span class="status-circle-green mx-3"></span>' : '<span class="status-circle-red mx-3"></span>';
      }
    }

    this.total.revenue.actual = actual;
    this.total.revenue.budget = budget;

    this.tableIncome.rows.add(this.accounts.income).draw();
    this.spinner = false;
  }

  loadExpense(year, month, program){
    this.accounts.expenses = [];
    let actual = 0; let budget = 0;

    if(month > 0 && this.expense[year] && !this.toggle)
      this.dataMonthly(this.expense[year], month, program, 2);
    if(this.toggle)
      this.dataYearly(this.expense, year, program, 2);
    
    this.tableExpense.rows().remove().draw();

    if(this.accounts.expenses.length > 0){
      for(let i in this.accounts.expenses){
        actual += this.accounts.expenses[i].actual; budget += this.accounts.expenses[i].budget;
        this.accounts.expenses[i].status = this.accounts.expenses[i].actual > this.accounts.expenses[i].budget ? '<span class="status-circle-red mx-3"></span>' : '<span class="status-circle-green mx-3"></span>';
      }
    }

    this.total.expenses.actual = actual;
    this.total.expenses.budget = budget;

    this.tableExpense.rows.add(this.accounts.expenses).draw();
    this.spinner = false;

    if(this.total.revenue.actual > 0 || this.total.expenses.actual > 0){
      this.loadPieChart();
    }

    if(this.income[this.form.value.year] || this.expense[this.form.value.year]) {
      this.loadLineChart();
    }
  }

  loadProject(year, month, program){
    this.accounts.project = []; this.total.raised = 0; this.total.need = 0;
    let tmp = [];
    let cart: any = this.project ? Object.values(this.project) : [];

    console.log(cart);

    if(this.toggle){  
      // console.log(program.id);
      if(program && program.id != 0) cart = cart.filter(q => q.program.id === program.id.toString());
      for(let i in cart){
        tmp = [];
        if(cart[i][year]){
          for(let j in cart[i][year]){
            for(let k in cart[i][year][j]){
              tmp.push(cart[i][year][j][k]);
            }
          }
          cart[i].accounts = Object.values([...tmp].reduce((acc, { id, name, need, raised }) => {
            acc[id] = { id, name, need: (acc[id] ? acc[id].need : 0) + need, raised: (acc[id] ? acc[id].raised : 0) + raised  };
            return acc;
          }, {}));
        }else{
          cart.splice(i, 1);
        }
      }
    }else{ // monthly
      if(program && program.id != 0){
        tmp = cart.filter(q => q.program.id == program.id);
        for(let i in tmp){
          if(cart[i][year] && cart[i][year][month]) tmp[i].accounts = Object.values(cart[i][year][month]);
          else cart.splice(i, 1);
        }
        cart = tmp; 
      }else {
        for(let i in cart){
          if(cart[i][year] && cart[i][year][month]) cart[i].accounts = Object.values(Object.values(cart[i][year][month]));
          else cart.splice(i, 1);
        }
      }
    }
    
    this.accounts.project = cart;
  
    this.chRef.detectChanges();

    for(let i in cart){
      let need = 0; let raised = 0; 
      this.accounts.project[i].total = {};

      for(let j in cart[i].accounts){
        need += cart[i].accounts[j].need;
        raised += cart[i].accounts[j].raised;
        cart[i].accounts[j].status = cart[i].accounts[j].need > cart[i].accounts[j].raised ? '<span class="status-circle-red mx-3"></span>' : '<span class="status-circle-green mx-3"></span>';
      }

      this.total.raised += raised;
      this.total.need += need;

      this.accounts.project[i].total.need = need;
      this.accounts.project[i].total.raised = raised;

      const c: any = $('#project' + i);

      this.tableProject[i] = c.DataTable({
        language: {
          search: '',
          searchPlaceholder: "Search"
        },
        responsive: true,
        destroy: true,
        "columns": [
          { "data": "name" },
          { 
            data: "need", render: function(data, type, row){
              return MoneyPipe.prototype.transform(data)
            } 
          },
          { 
            data: "raised", render: function(data, type, row){
              return MoneyPipe.prototype.transform(data)
            } 
          },
          { "data": "status" },
      ]});

      this.tableProject[i].rows().remove().draw();
      this.tableProject[i].rows.add(cart[i].accounts).draw();
    }
    
  }

  ngOnInit() {

    let options: any = {
      idToken: this.idToken
    }

    let data = new URLSearchParams(options);
    for(let i in options) data.set(i, options[i]);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded;charset=UTF-8',
      })
    };

    this.db.getNonprofit(this.nonprofit.id).then((res: any) => {
      this.nonprofit.name = res.name;
      this.nonprofit.city = res.city;
      this.nonprofit.img = res.img;
      this.nonprofit.agent = res.agent;
      this.nonprofit.website = res.website;
      this.nonprofit.slug = this.slugify(this.nonprofit.name);

      this.title.setTitle('Fundra - ' + this.nonprofit.name + ' Income Statement');

      this.http.post('https://us-central1-fundra-ade00.cloudfunctions.net/checkIdToken', data.toString(), httpOptions).subscribe((res: any) => {
        this.db.getUser(res.user_id).then((res: any) => {
          this.user = res;
          this.user.shortname = res.firstName.substring(0,1) + res.lastName.substring(0,1);
          console.log(res);
        })
        if(res.user_id != 'heGRHThSzKf0lJgWCwbgH0imHwu2' && res.user_id != this.nonprofit.agent)
          window.location.href = 'https://gofundra.com/signin';
      }, (err: HttpErrorResponse) => {
          console.log(err.error.message);
          window.location.href = 'https://gofundra.com/signin';
      });
    });

    let columns: any = [
      { data: "name" },
      { 
        data: "actual", render: function(data, type, row){
          return MoneyPipe.prototype.transform(data)
        } 
      },
      { 
        data: "budget", render: function(data, type, row){
          return MoneyPipe.prototype.transform(data)
        } 
      },
      { data: "status" }  
    ];

    const a: any = $('#income');
    this.tableIncome = a.DataTable({
      responsive: true,
      columns: columns,
      columnDefs: [
        { "visible": true, "targets": 3 }
      ],
      language: {
        search: '',
        searchPlaceholder: "Search"
      }
    });

    const b: any = $('#expense');
    this.tableExpense = b.DataTable({
      responsive: true,
      columns: columns,
      language: {
        search: '',
        searchPlaceholder: "Search"
      }
    });


    this.db.getPrograms(this.nonprofit.id).then((res) => {
      this.listPrograms = res;
      this.listPrograms.splice(0, 0, {id: 0, name: 'All Programs'});
      this.program = this.form.value.program = this.listPrograms[0];

      this.form = this.fb.group({
        month: this.month,
        year: this.year,
        program: this.program
      });
      
      this.db.getIncome(this.nonprofit.id).then((res: any) => {
        this.income = res;
        this.loadIncome(this.year, this.month, this.program);
      });

      this.db.getExpenses(this.nonprofit.id).then((res: any) => {
        this.expense = res;
        this.loadExpense(this.year, this.month, this.program);
      });

      this.db.getProject(this.nonprofit.id).then((res: any) => {
        this.project = res;
        this.loadProject(this.year, this.month, this.program);
      });
    });
  }

  slugify(string) {
    const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;'
    const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')
  
    return string.toString().toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
  }
}