// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyAt52-6bmI2uu_14aVW9nZ-TALIWjtVO0A',
    authDomain: 'fundra-ade00.firebaseapp.com',
    databaseURL: 'https://fundra-ade00.firebaseio.com',
    projectId: 'fundra-ade00',
    storageBucket: 'fundra-ade00.appspot.com',
    messagingSenderId: '1038070183955'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
