import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatter',
})
export class FormatterPipe implements PipeTransform {

  constructor() {
    
  }

  ionViewDidLoad(){

  }

  transform(num: any) {
    return Math.abs(num) > 999 ? Math.sign(num) * <any>((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num);
  }
}
