export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyAt52-6bmI2uu_14aVW9nZ-TALIWjtVO0A',
    authDomain: 'fundra-ade00.firebaseapp.com',
    databaseURL: 'https://fundra-ade00.firebaseio.com',
    projectId: 'fundra-ade00',
    storageBucket: 'fundra-ade00.appspot.com',
    messagingSenderId: '1038070183955'
  }
};
