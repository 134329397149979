import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NonprofitsComponent } from './nonprofits/nonprofits.component';

const routes: Routes = [
  { path: '', redirectTo: 'nonprofits/', pathMatch: 'full' },
  { path: 'nonprofits/', component: NonprofitsComponent },
  { path: 'nonprofits/:id/:idtoken', component: NonprofitsComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
